$paginationSelectors: (
        ".mq-pagination-item",
        ".mq-pagination-ellipsis",
        ".mq-pagination-prev",
        ".mq-pagination-next",
        ".mq-pagination-last",
        ".mq-pagination-first"
);
$md-size: 1.75rem;
$lg-size: 2.5rem;

.mq-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gl-spacing-scale-3);

  &.md {
    #{$paginationSelectors} {
      height: $md-size;
      font-size: var(--mq-font-size-scale-2);
    }

    .pagination-item {
      border-radius: calc($md-size / 2);
    }
  }

  &.lg {
    #{$paginationSelectors} {
      height: $md-size;
      font-size: var(--mq-font-size-scale-6);
    }

    .pagination-item {
      border-radius: calc($lg-size / 2);
    }
  }

  #{$paginationSelectors} {
    color: var(--color-info-blue);
    cursor: pointer;
    aspect-ratio: 1/1;

    .pagination-item {
      line-height: var(--mq-line-height-lg);
      text-align: center;
      border: 0;
      padding: var(--gl-spacing-scale-2);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      &:hover {
        background: var(--color-neutral-bkg);
        color: var(--color-neutral-text-med);
      }
    }

    &.disabled {
      color: var(--color-info-disabled-text);
      cursor: initial;
      pointer-events: none;
    }

    &.active {
      cursor: initial;

      .pagination-item {
        font-weight: bold;
        background-color: var(--color-brand-blue);
        border-color: var(--color-brand-blue);
        color: var(--color-neutral-text-light);
      }
    }

    &:focus, &:active {
      outline: none;
      color: var(--color-brand-indigo);
    }
  }
}
